import React from 'react';
import './MovingDots.scss';

const MovingDots = () => {
  return (
    // <div className="moving-dots--wrapper">
    <div className="moving-dots">
      <span />
      <span />
      <span />
      <span />
      <span />
    </div>
    // </div>
  );
};

export default MovingDots;
